

































































































































































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "AppNavigation",
  data: () => ({
    login_url:
      process.env.VUE_APP_OBLIVION_API + "/authentication/discord/start",
  }),
  async mounted() {
    await this.$store.dispatch("isAuthenticated");
    if (this.$store.state.isAuthenticated) {
      await this.$store.dispatch("getDiscordUserDetails");
      await this.$store.dispatch("getDiscordUserRoles");
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
});
