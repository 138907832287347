import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"position":"relative"},attrs:{"fluid":""}},[_c(VForm,{ref:"form",staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"table pb-md-0 mb-10"},[_c(VRow,{staticClass:"white--text py-3 pl-3 sticky-row",staticStyle:{"border-bottom":"1px solid white"}},[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"1"}},[_c('h3',[_vm._v("Filters:")])]),_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"2"}},[_c(VTextField,{attrs:{"dense":"","dark":"","outlined":"","label":"Customer Name","clearable":"","hide-details":"true"},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1),_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"2"}},[_c(VTextField,{attrs:{"dense":"","dark":"","outlined":"","label":"RunId","clearable":"","hide-details":"true"},model:{value:(_vm.runId),callback:function ($$v) {_vm.runId=$$v},expression:"runId"}})],1),_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","md":"1"}},[_c(VBtn,{attrs:{"color":"green"},on:{"click":function($event){return _vm.getSpecificLogs()}}},[_vm._v("Search")])],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1 frame",attrs:{"items":_vm.bookedCustomerLogs,"headers":_vm.headers,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticStyle:{"color":"#ffffff"},attrs:{"small":"","text":""},on:{"click":function($event){return _vm.toggleDetails(item.customer.id)}}},[_vm._v(" "+_vm._s(_vm.expandedLogs.includes(item.customer.id) ? "Hide" : "Show")+" Customer ")]),_c(VExpandTransition,[(_vm.expandedLogs.includes(item.customer.id))?_c('div',{staticClass:"ml-4 mt-2"},_vm._l((item.customer),function(value,key){return _c('div',{key:key},[_c('strong',[_vm._v(_vm._s(key)+":")]),_c('p',[_vm._v(_vm._s(value))])])}),0):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }